import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Nav, Footer } from '@bitcoin-portal/bitcoincom-universal';
import {
  H1,
  Paragraph,
  ContentBlock,
  Link,
} from '@bitcoin-portal/bitcoincom-pkg-components';
import { Wrapper } from './styled';
import SEO from './SEO';

const hashReg = new RegExp(/^[0-9a-f]*$/, 'i');

function isHash(hash) {
  if (typeof hash !== 'string') return false;
  return hashReg.test(hash);
}

function isSHA256Hash(hash) {
  return isHash(hash) && hash.length === 64;
}

if (typeof window !== 'undefined') {
  const pathName = window.location.pathname;

  // See if the route tried was the old format of a proof page
  const hashTest = pathName.slice(7);

  // Redirect to proof page
  if (isSHA256Hash(hashTest)) {
    window.location = `${window.origin}/proof/?hash=${hashTest}`;
  }
}

const ErrorPage = ({ locale, intl: { messages } }) => (
  <>
    <SEO />
    <Wrapper>
      <Nav locale={locale} showLang={false} />
      <ContentBlock
        aside={
          <img
            alt={messages['404.content.image-alt']}
            src={messages['404.content.image']}
            width="100%"
          />
        }
      >
        <H1>
          <FormattedMessage id="404.content.title" />
        </H1>
        <Paragraph>
          <FormattedMessage id="404.content.subtitle" />
        </Paragraph>
        <Paragraph>
          <Link href="/">
            <FormattedMessage id="404.link.home" />
          </Link>
        </Paragraph>
      </ContentBlock>
      <Footer locale={locale} />
    </Wrapper>
  </>
);

ErrorPage.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    messages: PropTypes.object,
  }).isRequired,
};
export default injectIntl(ErrorPage);
